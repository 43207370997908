    
    <app-navbar></app-navbar>
    <h1 class="mt-5">Privacy Policy</h1>

    <div class="container">
        <p>We, M/s Localfirst Software Solutions Private Limited, a private limited company registered under Companies
            Act 2013 bearing CIN U62099TS2024PTC185731, represented by its members, hereinafter referred to as the
            "Company" (where such expression shall, unless repugnant to the context thereof, be deemed to include its
            respective legal heirs, representatives, administrators, permitted successors and assigns.) The creator of
            this Privacy Policy ensures a steady commitment to Your privacy with regard to the protection of your
            invaluable information.</p>

        <p>This privacy policy contains information about the Mobile Application "Localfirst" and its Website
            "https://localfirst.co.in" (hereinafter collectively referred to as the "Platform"). In order to provide
            You with Our uninterrupted use of services, We may collect and, in some circumstances, disclose information
            about you with your permission. To ensure better protection of Your privacy, We provide this notice
            explaining Our information collection and disclosure policies, and the choices You make about the way Your
            information is collected and used.</p>

        <p>This Privacy Policy shall be in compliance with the General Data Protection Regulation (GDPR) in effect from
            May 25, 2018, and any and all provisions that may read to the contrary shall be deemed to be void and
            unenforceable as of that date. If you do not agree with the terms and conditions of our Privacy Policy,
            including in relation to the manner of collection or use of your information, please do not use or access
            the Site. If you have any questions or concerns regarding this Privacy Policy, you should contact our team
            at support&#64;localfirst.co.in</p>

        <p>ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED TO THEM UNDER THIS AGREEMENT. FURTHER,
            ALL HEADING USED HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE AGREEMENT IN
            ANY MANNER. NEITHER THE USER NOR THE CREATORS OF THIS PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE
            PROVISIONS CONTAINED WITHIN IT IN ANY MANNER.</p>

        <h2>1. Definitions:</h2>
        <p>"We", "Our", and "Us" shall mean and refer to the Platform and/or the Company, as the context so requires.</p>
        <p>"You", "Your", "Yourself", "User" and "Customer" shall mean and refer to natural and legal individuals who
            use the Platform and who is competent to enter into binding contracts, as per Indian laws.</p>
        <p>'Users' shall refer to the individuals using either or both of the platform "Services" refer to providing a
            digital communication platform where people can connect with new people, brands, businesses without sharing
            their personal mobile number where the User will be given a specific "LocalFirst". Services provided by the
            platform with the usage of the LocalFirst, User's can make free voice calls, send messages, and send
            disappearing messages that are meant to disappear.</p>
        <p>The mission of development of this Application is to protect the privacy of peoples.</p>
        <p>"Third Parties" refer to any Application, Company or individual apart from the User and the creator of this
            Platform.</p>
        <p>The term "Platform" refers to the Mobile Application and/ or Website of the Company.</p>
        <p>The term "Mobile Application" refers to the Application name "LocalFirstt" which provides the User with the
            information on the Platform and can also be used to avail the services of the Company.</p>
        <p>The term "Website" refers to the domain name <a href="http://localfirst.co.in">http://localfirst.co.in</a> which
            provides the User with the information on the Platform and can also be used to avail the services of the
            Company.</p>
        <p>"Personal Information" shall mean and refer to any personally identifiable information that We may collect
            from You such as Name, Location, Age, Gender, Mobile number, profile Photo etc. For removal of any doubts,
            please refer to Clause 2.</p>

        <h2>2. Information We Collect</h2>
        <p>We are committed to respecting Your online privacy. We further recognize Your need for appropriate protection
            and management of any Personal Information You share with us. We may collect the following information:</p>
        <ol type="a">
            <li>Primary level data:
                <ul>
                    <li>Name of the user</li>
                    <li>Email Address</li>
                    <li>Business name</li>
                    <li>Business Address</li>
                    <li>Business Address Location</li>
               
                    <li>Mobile Number</li>
                    <li>IP Address</li>
                    <li>Device-specific details such as Phone operating system type, device type etc...</li>
                </ul>
            </li>
            <li>Secondary level data
                <ul>
                    <li>App usage activity</li>
                    <li>User engagement data</li>
                    <li>Frequency of usage</li>
                </ul>
            </li>
            <li>Tracking Information such as, but not limited to the IP address of your device and Device ID when
                connected to the Internet. This information may include the URL that you just came from (whether this URL
                is on the Platform or not), which URL you next go to (whether this URL is on the Platform or not), your
                computer or device browser information, and other information associated with your interaction with the
                Website or application;</li>
            <li>Details of Website/Application usage for analytics. This privacy policy also applies to data we collect
                from users who are not registered as members of this site, including, but not limited to, browsing
                behaviour, pages viewed etc. We also collect and store personal information provided by you from time to
                time on the Site. We only collect and use such information from you that we consider necessary for
                achieving a seamless, efficient and safe experience, customized to your needs including:</li>
            <ol>
                <li>To enable the provision of services opted for by you;</li>
                <li>To communicate the necessary account and service related information from time to time;</li>
                <li>To allow you to receive quality customer care services and data Collection;</li>
                <li>To comply with applicable laws, rules and regulations;</li>
            </ol>
        </ol>
        <p>Where any service requested by you involves a third party, such information as is reasonably necessary by the
            Company to carry out your service request may be shared with such third party only after taking your
            concent. The Company may also use contact information internally to direct its efforts for service
            improvement but shall immediately delete all such information upon withdrawal of your consent.</p>
        <p>To the extent possible, we provide you with the option of not divulging any specific information that you
            wish for us not to collect, store or use. You may also choose not to use a particular service or feature on
            the Site and opt-out of any non-essential communications from the platform.</p>
        <p>Further, transacting over the internet has inherent risks which can only be avoided by you following security
            practices yourself, such as not revealing account/login related information to any other person and
            informing our customer care team about any suspicious activity or where your account has/may have been
            compromised.</p>


             <h2>3. Our Use of Your Information</h2>
        <p>The information provided by you shall be used to provide and improve the service for you and all users.</p>
        <ol>
            <li>For maintaining an internal record</li>
            <li>For enhancing the Services provided.</li>
        </ol>
        <p>For more details about the nature of such communications, please refer to our Terms of Service. Further, your
            personal data and Sensitive Personal data may be collected and stored by Us for internal record. We use Your
            tracking information such as IP addresses, and or Device ID to help identify you and to gather broad
            demographic information and make further services available to you. We will not sell, license or trade Your
            personal information. We will not share your personal information with others unless they are acting under
            our instructions or we are required to do so by law. Information collected via Our server logs includes
            users' IP addresses and the pages visited; this will be used to manage the web system and troubleshoot
            problems. We also use third-party analytics, tracking, optimization and targeting tools to understand how
            users engage with our Platform so that we can improve it and cater personalized content/ads according to
            their preferences.</p>

        <h2>4. How Information Is Collected</h2>
        <p>Before or at the time of collecting personal information, we will identify the purposes for which
            information is being collected. If the same is not identified to you, you have the right to request the
            Company to elucidate the purpose of collection of said personal information, pending the fulfilment of which
            you shall not be mandated to disclose any information whatsoever.</p>
        <p>We will collect and use your personal information solely with the objective of fulfilling those purposes
            specified by us, within the scope of the consent of the individual concerned or as required by law. We will
            only retain personal information as long as necessary for the fulfilment of those purposes. We will collect
            personal information by lawful and fair means and with the knowledge and consent of the individual concerned.
        </p>
        <p>Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary
            for those purposes, should be accurate, complete, and up-to-date.</p>

        <h2>5. Google Analytics</h2>
        <p>We may use Google Analytics to help us to understand how you make use of our content and work out how we can
            make things better. These analytics follow your progress through us, collecting anonymous data on where you
            have come from, which pages you visit, and how long you spend on the site. This data is then stored by
            Google in order to create reports. These analytics do not store your personal data.</p>
        <p>The information generated by the analytics about your use of the Website, including your IP address, may be
            transmitted to and stored by Google on servers in the United States. Google may use this information for the
            purpose of evaluating your use of the website, compiling reports on website activity for us and providing
            other services relating to website activity and internet usage. Google may also transfer this information to
            third parties where required to do so by law, or where such third parties process the information on
            Google's behalf. Google will not associate your IP address with any other data held by Google. By using this
            website, you consent to the processing of data about you by Google in the manner and for the purposes set
            out above. The Google website contains further information about Analytics and a copy of Google's privacy
            policy pages.</p>

            <div class="privacy-policy">
        <h2>6. External Links on the Website</h2>
        <p>The Website or Application may include advertisements, hyperlinks to other websites, applications, content or
            resources. We have no control over any websites or resources, which are provided by companies or persons
            other than Us. You acknowledge and agree that we are not responsible for the availability of any such
            external sites or resources, and do not endorse any advertising, services/products or other materials on
            or available from such platform or resources. You acknowledge and agree that We are not liable for any loss
            or damage which may be incurred by you as a result of the availability of those external sites or
            resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any
            advertising, products or other materials on, or available from, such websites or resources. These external
            websites and resource providers may have their own privacy policies governing the collection, storage,
            retention and disclosure of Your Personal Information that You may be subject to. We recommend that you
            enter the external Website and review their privacy policy.</p>

        <h2>7. Your Rights</h2>
        <p>Unless subject to an exemption, you have the following rights with respect to your personal data:</p>
        <ul>
            <li>The right to request a copy of your personal data which we hold about you;</li>
            <li>The right to request for any correction to any personal data if it is found to be inaccurate or out of
                date;</li>
            <li>The right to withdraw Your consent to the processing at any time;</li>
            <li>The right to object to the processing of personal data;</li>
            <li>The right to lodge a complaint with a supervisory authority.</li>
            <li>The right to obtain information as to whether personal data are transferred to a third country or to an
                international organization.</li>
        </ul>
        <p>Where you hold an account with any of our services, you are entitled to a copy of all personal data which we
            hold in relation to you. You are also entitled to request that we restrict how we use your data in your
            account when you log in.</p>

        <h2>8. Confidentiality</h2>
        <p>You further acknowledge that the Platform may contain information which is designated confidential by us and
            that you shall not disclose such information without our prior written consent. Your information is
            regarded as confidential and therefore will not be divulged to any third party, unless if legally required
            to do so to the appropriate authorities. We will not sell, share, or rent your personal information to any
            third party or use your e-mail address for unsolicited mail. Any emails sent by us will only be in
            connection with the provision of agreed services, and you retain sole discretion to seek for discontinuation
            of such communications at any point of time.</p>

        <h2>9. Other Information Collectors</h2>
        <p>Except as otherwise expressly included in this Privacy Policy, this document only addresses the use and
            disclosure of information we collect from you. To the extent that you disclose your information to other
            parties, whether they are on our Platform or on other sites throughout the Internet, different rules may
            apply to their use or disclosure of the information you disclose to them. To the extent that we use third
            party advertisers, they adhere to their own privacy policies. Since we do not control the privacy policies
            of the third parties, you are subject to ask questions before you disclose your personal information to
            others.</p>

        <h2>10. Our Disclosure of Your Information</h2>
        <p>We may host surveys for survey creators for our platform who are the owners and users of your survey
            responses. We do not own or sell your responses. Anything you expressly disclose in your responses will be
            disclosed to survey creators. Please contact the survey creator directly to better understand how they might
            share your survey responses. Information collected will not be considered as sensitive if it is freely
            available and accessible in the public domain or is furnished under the Right to Information Act, 2005, any
            rules made there under or any other law for the time being in force. Due to the existing regulatory
            environment, we cannot ensure that all of your private communications and other personally identifiable
            information will never be disclosed in ways not otherwise described in this Privacy Policy. By way of
            example (without limiting and foregoing), we may be forced to disclose information to the government, law
            enforcement agencies or third parties. Therefore, although we use industry-standard practices to protect
            your privacy, we do not promise, and you should not expect, that your personally identifiable information or
            private communications would always remain private. We do however assure you that any and all disclosure of
            your personally identifiable information shall be personally intimated to you through an email sent to your
            provided email address. As a matter of policy, we do not sell or rent any personally identifiable
            information about you to any third party. However, the following describes some of the ways that your
            personally identifiable information may be disclosed:</p>
        <ul>
            <li><strong>External Service Providers:</strong> There may be a number of services offered by external
                service providers that help you use our Platform. If you choose to use these optional services, and in
                the course of doing so, disclose information to the external service providers, and/or grant them
                permission to collect information about you, then their use of your information is governed by their
                privacy policy.</li>
            <li><strong>Law and Order:</strong> We cooperate with law enforcement inquiries, as well as other third
                parties to enforce laws, such as intellectual property rights, fraud and other rights. We can (and you
                authorize us to) disclose any information about you to law enforcement and other government officials
                as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of
                fraud, intellectual property infringements, or other activity that is illegal or may expose us or you
                to legal liability.</li>
        </ul>

        <h2>11. Accessing, Reviewing and Changing Your Profile</h2>
        <p>Following registration, you can review and change the information you submitted at the stage of registration,
            except Email ID and mobile number. An option for facilitating such change shall be present on the Platform
            and such change shall be facilitated by the User. If you change any information, we may or may not keep
            track of your old information. We will not retain in our files information you have requested to remove for
            certain circumstances, such as to resolve disputes, troubleshoot problems and enforce our terms and
            conditions. Such prior information shall be completely removed from our databases, including stored 'back
            up' systems. If you believe that any information, we are holding on you is incorrect or incomplete, or to
            remove your profile so that others cannot view it, the User needs to remediate, and promptly correct any
            such incorrect information.</p>

        <h2>12. Security</h2>
        <p>We treat data as an asset that must be protected against loss and unauthorized access. We employ many
            different security techniques to protect such data from unauthorized access by members inside and outside
            the Company. We follow generally accepted industry standards to protect the Personal Information submitted
            to us and information that we have accessed. However, as effective as encryption technology is, no security
            system is impenetrable. Our Company cannot guarantee the security of our database, nor can we guarantee
            that information you provide won't be intercepted while being transmitted to the Company over the Internet.
        </p>

        <h2>13. Severability</h2>
        <p>Each paragraph of this Privacy Policy shall be and remain separate from and independent of and severable
            from all and any other paragraphs herein except where otherwise expressly indicated or indicated by the
            context of the agreement. The decision or declaration that one or more of the paragraphs are null and void
            shall have no effect on the remaining paragraphs of this privacy policy.</p>

        <h2>14. Amendment</h2>
        <p>Our Privacy Policy may change from time to time. The most current version of the policy will govern our use
            of your information and will always be at the Platform. Any amendments to this Policy shall be deemed as
            accepted by the User on their continued use of the Platform.</p>

        <h2>15. Automated Decision Making</h2>
        <p>As a responsible Company, we do not use automatic decision-making or profiling.</p>

        <h2>16. Consent Withdrawal, Data Download & Data Removal Requests</h2>
        <p>To withdraw your consent, or to request the download or delete your data with us for any or all our products
            & services at any time, please email to support&#64;localfirst.co.in</p>

        <h2>17. Contact Us</h2>
        <p>If you have any questions or concerns regarding this privacy policy, you should contact us by sending an
            e-mail to please email to support&#64;locafirst.co.in. Information provided on the website may not be 100%
            accurate and may be provided for promotional purposes of the business.</p>
    </div>
    </div>
    