<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">
    <div class='d-flex'>
       <img src="../../../assets/images/App Launcher.svg" class="mr-2 n-1">
        <h2 class="m-0">LocalFirst</h2>
    </div></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
    
      <li class="nav-item">
        <!-- <a class="nav-link" href="#">Industries</a> -->
        <a class="nav-link" href="/reviews">Reviews</a>
      </li>
      <li class="nav-item">
        <!-- <a class="nav-link" href="#">Industries</a> -->
        <a class="nav-link" href="/Referrals">Referrals</a>
      </li>
      <!-- <li class="nav-item s-2">
        <a class="nav-link cursor"  (click)="bussiness_typeBtn()">Local Businsses</a>
           <ul class="hover_effect_1" *ngIf="bussiness_type">
    


            <li>
            <div class="convert_section Type_business_type pt-3 ml-md-5">
               <div class="reviews_navbar type_b" *ngFor="let easier of cardsDetails">
                  <h4><a class="cursor" (click)="navigate(easier.name)">{{easier.name}}</a></h4>
               </div>
        
            </div>
          </li>
           
        </ul>
      </li>
      <li class="nav-item s-1" >
        <a class="nav-link cursor"   (click)="solutionBtn()">Tools</a>
        <ul class="hover_effect" *ngIf="solutions">
          <li>
            <div class="convert_section solutions_1 pt-3 ml-md-5">
          
               <div class="reviews_navbar" *ngFor="let type of solutionsArray">
                  <h4><a  >{{type.name}}</a></h4>
                  <p>Improve your google score and ranking</p>
               </div>
                
            </div>
          </li>
       
        
        
        </ul>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" href="/pricing">Pricing</a>
      </li> -->
      <!-- <li class="nav-item">
       
        <a class="nav-link demo_btn" href="/Earn"><button class="cursor">Earn with localfirst </button></a>
      </li> -->
      <li class="nav-item" *ngIf="token == null">
        <a class="nav-link" href="/merchants/login">Login</a>
      </li>
      <li class="nav-item cursor" *ngIf="token != null">
        <a class="nav-link" (click)="logout()">Logout</a>
      </li>
 
    
     
      
    </ul>
    <div class="d-flex justify-content-center">
        <!-- <div class="sign-in mr-5">
        
        <a  href="#">Sign In</a>
    
        </div> -->
        <div class="demo_btn">
       
        </div>
        </div>
  </div>
</nav>