import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { HomeComponent } from './components/home/home.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { EarnwithAutomatikComponent } from './components/earnwith-automatik/earnwith-automatik.component';
import { CommonbussiesstypeComponent } from './components/commonbussiesstype/commonbussiesstype.component';
import { AutopliotComponent } from './components/autopliot/autopliot.component';
import { SmsmarketingComponent } from './components/smsmarketing/smsmarketing.component';
import { ReferralsComponent } from './components/referrals/referrals.component';
import { TeammangementComponent } from './components/teammangement/teammangement.component';
import { TermsofserviceComponent } from './components/termsofservice/termsofservice.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { CancellationpolicyComponent } from './components/cancellationpolicy/cancellationpolicy.component';
import { RefundpolicyComponent } from './components/refundpolicy/refundpolicy.component';
import { LoginComponent } from './components/login/login.component';
import { FreetrailrunningComponent } from './components/subscription/freetrailrunning/freetrailrunning.component';
import { SubscriptionexpiredComponent } from './components/subscription/subscriptionexpired/subscriptionexpired.component';
import { SubscriptioncancelledComponent } from './components/subscription/subscriptioncancelled/subscriptioncancelled.component';
import { LoginGuard } from './_routes/login.guard';

const routes: Routes = [
  { path: '', component: ReviewsComponent },
  { path: 'merchants/login', component: LoginComponent },
  {
    path: 'merchants/freetrail',
    component: FreetrailrunningComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'merchants/subscriptionExpired',
    component: SubscriptionexpiredComponent,
  },
  {
    path: 'merchants/subscriptionCancelled',
    component: SubscriptioncancelledComponent,
  },
  { path: 'reviews', component: ReviewsComponent },
  // { path: 'pricing', component: PricingComponent },
  // { path: 'Earn', component: EarnwithAutomatikComponent },
  // { path: 'Team-management', component: TeammangementComponent },
  { path: 'AboutUs', component: AboutusComponent },
  { path: 'ContactUs', component: ContactusComponent },
  { path: 'Referrals', component: ReferralsComponent },
  // { path: 'Sms-marketing', component: SmsmarketingComponent },
  // { path: 'Autopliot', component: AutopliotComponent },
  { path: 'Terms-of-service', component: TermsofserviceComponent },
  { path: 'terms-of-service', component: TermsofserviceComponent },
  { path: 'Privacy-policy', component: PrivacypolicyComponent },
  { path: 'privacy-policy', component: PrivacypolicyComponent },
  { path: 'Cancellation-policy', component: CancellationpolicyComponent },
  { path: 'Refund-policy', component: RefundpolicyComponent },
  // { path: ':name', component: CommonbussiesstypeComponent },
  { path: '**', component: ReviewsComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
