<app-navbar></app-navbar>
<div class="main">
     <!-- <div class="push_button_section">
      <div class="container pt-md-5">
        <div class="row">
            <div class="col-md-6">
          <div class="button_push_section">
              <h5>More <span>reviews.</span> Less effort.</h5>
              <p>Double your monthly review volume in less than 60 days and increase website and foot traffic to your business.</p>
              <div class="d-flex">
                 <div class="form_Section">
                    <input placeholder="Enter e-mail">
                 </div>
                 <div class="submit_section ml-md-4">
                    <button class="cursor">Submit</button>
                 </div>
              </div>
              <div class="demo_review_section d-flex mt-3 pt-3">
                  <div class="demo_btn mr-3">
        <button class="cursor">Get a Demo</button>
        </div>
                  <div class="see_pricing">
        <button class="cursor">See Pricing</button>
        </div>
              </div>
          </div>
            </div>
            <div class="col-md-6"></div>
        </div>
         
      </div>
   </div> -->
 <div class="push_button_section">
      <div class="container pt-md-5">
        <div class="row">
            <div class="col-md-6">
          <div class="button_push_section">
              <h5>Push a button, get <span>Google reviews</span> for your local business</h5>
              <p>Maintain a winning reputation, engage digitally, and deliver an exceptional customer experience - all from one intuitive platform.</p>
              <div class="d-flex">
                 <!-- <div class="form_Section">
                    <input placeholder="Enter e-mail">
                 </div> -->
                 <div class="submit_section">
                    <button class="cursor" disabled>Launching soon</button>
                 </div>
              </div>
          </div>
            </div>
            <div class="col-md-6"></div>
        </div>
         
      </div>
   </div>




<div class="how_it_works mt-md-5 pt-md-4">
<div class=" beat_your_competetion container"  >

      
        <h4>How it works?</h4>
        <p>Win every lead, and drive repeat business. Industries Win every lead, and drive repeat business. Industries Win every lead, and drive repeat business.</p>
        </div>


        <div class="stepper_class mt-md-5 pt-md-3">
           <div class="d-flex justify-content-start stepper" *ngFor="let details of steepper">
              <div class="counter_section mr-4">
                 <p>{{details.count}}</p>
                 <span *ngIf="details.view"></span>
              </div>
              <div class="counter_text_section">
                 <h3>{{details.header}}</h3>
                 <p>{{details.details}}</p>
              </div>
           </div>
        </div>
</div>
     
   

   <div class=" pt-md-5">
      <div class=" beat_your_competetion container"  >

        
        <h4>The fastest and most easiest way to request Google reviews from customers</h4>
       
        </div>
         <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/reviews-1.svg">
                </div>
                <div class="col-md-6 mt-md-4">
                  <button class="cursor features">Features</button>
                    <h5>Request for a review</h5>
                    <ul>
                      <li>Drive more reviews and website traffic. </li>
                      <li>Grow < Business> reputation with Continuous Google Reviews. </li>
                      <li>Easily submit reviews through a user-friendly interface, making it simple for customers to share their thoughts. </li>
                      <li>Implement a detailed rating system that goes beyond stars, allowing customers to provide nuanced feedback on different aspects of their experience. </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                      <!-- <button class="learn ml-5">Learn more ></button> -->
                    </div>
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    <button class="cursor features">Features</button>
                    <h5>App Sends SMS with Review Link</h5>
                    <ul>
                      <li>Get more leads with automated referral campaigns. </li>
                      <li>Send automated and measurable referral campaigns at scale in just a few clicks. </li>
                      <li>Stay in the loop with our referral tracking feature. Monitor the status of your referrals and see the impact of your network. </li>
                      <li>Familiarize yourself with our referral program terms and conditions to ensure a smooth and rewarding experience. </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                      <!-- <button class="learn ml-5">Learn more ></button> -->
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/reviews-2.svg">

                
                </div>
              </div>
           </div>
             <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/reviews-3.svg">
                </div>
                <div class="col-md-6 mt-md-4">
                  <button class="cursor features">Features</button>
                    <h5>Post review</h5>
                    <ul>
                      <li>Drive more reviews and website traffic. </li>
                      <li>Grow < Business> reputation with Continuous Google Reviews. </li>
                      <li>Easily submit reviews through a user-friendly interface, making it simple for customers to share their thoughts. </li>
                      <li>Implement a detailed rating system that goes beyond stars, allowing customers to provide nuanced feedback on different aspects of their experience. </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                      <!-- <button class="learn ml-5">Learn more ></button> -->
                    </div>
                </div>
              </div>
           </div>
         <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <button class="cursor features">Features</button>
                    <h5>Push Notifications</h5>
                    <ul>
                      <li>Drive more reviews and website traffic. </li>
                      <li>Grow < Business> reputation with Continuous Google Reviews. </li>
                      <li>Easily submit reviews through a user-friendly interface, making it simple for customers to share their thoughts. </li>
                      <li>Implement a detailed rating system that goes beyond stars, allowing customers to provide nuanced feedback on different aspects of their experience. </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                      <!-- <button class="learn ml-5">Learn more ></button> -->
                    </div>
                </div>
                <div class="col-md-6 mt-md-4">
                  
                  <img src="../../../assets/images/reque-reviews-3.svg">

                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                  <img src="../../../assets/images/reviews-5.svg">

                    
                </div>
                <div class="col-md-6 ">

                <button class="cursor features">Features</button>
                    <h5>View review & replyS</h5>
                    <ul>
                      <li>Get more leads with automated referral campaigns. </li>
                      <li>Send automated and measurable referral campaigns at scale in just a few clicks. </li>
                      <li>Stay in the loop with our referral tracking feature. Monitor the status of your referrals and see the impact of your network. </li>
                      <li>Familiarize yourself with our referral program terms and conditions to ensure a smooth and rewarding experience. </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                      <!-- <button class="learn ml-5">Learn more ></button> -->
                    </div>
                </div>
              </div>
           </div>
            <!-- <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/reviews-6.svg">
                </div>
                <div class="col-md-6 mt-md-4">
                  <button class="cursor features">Features</button>
                    <h5>Push Notifications</h5>
                    <ul>
                      <li>Drive more reviews and website traffic. </li>
                      <li>Grow < Business> reputation with Continuous Google Reviews. </li>
                      <li>Easily submit reviews through a user-friendly interface, making it simple for customers to share their thoughts. </li>
                      <li>Implement a detailed rating system that goes beyond stars, allowing customers to provide nuanced feedback on different aspects of their experience. </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                     
                    </div>
                </div>
              </div>
           </div> -->
           <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                   <button class="cursor features">Features</button>
                    <h5>Review analytics</h5>
                    <ul>
                      <li>Drive more reviews and website traffic. </li>
                      <li>Grow < Business> reputation with Continuous Google Reviews. </li>
                      <li>Easily submit reviews through a user-friendly interface, making it simple for customers to share their thoughts. </li>
                      <li>Implement a detailed rating system that goes beyond stars, allowing customers to provide nuanced feedback on different aspects of their experience. </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                      <!-- <button class="learn ml-5">Learn more ></button> -->
                    </div>
                </div>
                <div class="col-md-6 mt-md-4">
                  <img src="../../../assets/images/reviews-7.svg">
                 
                </div>
              </div>
           </div>
        </div>
         <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6 mt-md-4">
                  <h2>Get more positive reviews visible</h2>
                    <p class="auto">Continous reviews increases more posistive reviews. Local business
                    with posisitve reviews gets picked by your potentinal customers</p>
                    
                    <div class="started_button">
                      <button class="learn mt-4">Build trust and give confidence to customers</button>
                    </div>
                </div>
                <div class="col-md-6  ">
              
                  <img src="../../../assets/images/view-1.svg" class="value-img">
                    
                </div>
              </div>
           </div>
         <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/view-2.svg" class="value-img">
                </div>
                <div class="col-md-6 mt-md-4">
              
                    <h2>Get more enquiries, leads and acquire new customers</h2>
                    <p class="auto">Reviews helps you get more leads, more enquiries and more revenue for your local business</p>
                    
                    <div class="started_button">
                      <button class="learn mt-4">Don't hope for new customers. Systematically get new customers
                      with continous reviews</button>
                    </div>
                </div>
              </div>
           </div>
   <div class=" pt-md-5">
      <div class=" beat_your_competetion container"  >

        
        <h4>How Google Reviews Change your local business</h4>
       
        </div>
         <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/values-1.svg" class="value-img">
                </div>
                <div class="col-md-6 mt-md-4">
              
                    <h2>Be found before your competitors</h2>
                    <p class="auto">More reviews improves your local SEO rankings. It helps you get more visibility
                    in the Google search</p>
                    
                    <div class="started_button">
                      <button class="learn mt-4">Beat your local competitors </button>
                    </div>
                </div>
              </div>
           </div>
         <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6 mt-md-4">
                  <h2>Get more positive reviews visible</h2>
                    <p class="auto">Continous reviews increases more posistive reviews. Local business
                    with posisitve reviews gets picked by your potentinal customers</p>
                    
                    <div class="started_button">
                      <button class="learn mt-4">Build trust and give confidence to customers</button>
                    </div>
                </div>
                <div class="col-md-6  ">
              
                  <img src="../../../assets/images/values-2.svg" class="value-img">
                    
                </div>
              </div>
           </div>
         <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/values-3.svg" class="value-img">
                </div>
                <div class="col-md-6 mt-md-4">
              
                    <h2>Get more enquiries, leads and acquire new customers</h2>
                    <p class="auto">Reviews helps you get more leads, more enquiries and more revenue for your local business</p>
                    
                    <div class="started_button">
                      <button class="learn mt-4">Don't hope for new customers. Systematically get new customers
                      with continous reviews</button>
                    </div>
                </div>
              </div>
           </div>
        
            
  <div class=" pt-md-5">
      <div class="people_section_problem container">

        <button class="cursor">Industries</button>
        <h4>A simple SaaS app specifically built to help local business to grow their Google reviews</h4>
         <div class="row">
            <div class="col-md-4" *ngFor="let details of cardsDetails">
                <div class="card mb-md-4">
                    <div class="card-body">
                     <div class="text-center">
<img [src]="details.Image"  >
                         <h3>{{details.name}}</h3>
                         <p>{{details.details}}</p>
                         <!-- <a>Learn more  > </a> -->
                     </div>
                        
                    </div>
                </div>
            </div>
         </div>
      </div>
   </div>
              <!-- <div class="secure_section container my-md-4">
         <div class="section_secure">
            <div class="d-flex">
               <div class="leads_today lead_reiews mt-md-4">
                   <h4>Win every lead, and drive repeat business.</h4>
                   <p>Win every lead, and drive repeat business. Industries Win every lead, and drive repeat business. Industries Win every lead, and drive repeat business.</p>
                    <div class="demo_btn">
        <button class="cursor">Get a Demo</button>
        </div>
               </div>
               <div class="business_section ml-auto">
                  <img src="../../../assets/images/newsletter-subscription 1.svg">
               </div>
            </div>
         </div>
       </div> -->
       <!-- <div class="see_action_section">
 <div class=" beat_your_competetion minutes container my-md-5 "  >

      
        <h4>Eliminate the pain of manually asking for reviews, asking customers to scan QR codes. Just push a button
        and get Google reviews in minutes</h4>
        
        <img src="../../../assets/images/video-img.svg" class="video-icon">
        </div>
       </div> -->
      
        </div>
</div>
<app-footer></app-footer>