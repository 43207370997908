import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css'],
})
export class PricingComponent implements OnInit {
  pricingDetails: any[] = [
    {
      header: 'Single Location',
      para: 'Ideal for small businesses who have only one location',
      price: 'INR 6000/monthly per location',
      agree: '$75 per month',
      con: 'Unlimited',
      mem: 'Unlimited',
      listHeade: 'Powerful Essential  features:',
    },
    {
      header: 'Multiple Locations',
      para: 'Ideal for medium businesses who have up to five locations',
      price: 'INR 7000/Monthly per location',
      agree: '$85/month per location',
      con: 'Unlimited',
      mem: 'Unlimited',
      listHeade: 'Get these powerful features:',
    },
    {
      header: 'Enterprise',
      para: 'Ideal for small businesses who have more than five locations',
      price: 'INR 8300/Monthly per location',
      agree: '$100/month per location',
      con: 'Unlimited',
      mem: 'Unlimited',
      listHeade: 'Get these powerful features:',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
