import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/_services/services.service';
import { environment } from 'src/environments/environment';
declare var Razorpay: any;

@Component({
  selector: 'app-freetrailrunning',
  templateUrl: './freetrailrunning.component.html',
  styleUrls: ['./freetrailrunning.component.css'],
})
export class FreetrailrunningComponent implements OnInit {
  freeTrail: any = [
    {
      name: 'Locations :',
      count: 1,
    },
    {
      name: 'Review requests :',
      count: '500/month',
    },
    {
      name: 'Referral requests :',
      count: '500/month',
    },
    {
      name: 'Referral Leads :',
      count: 'Unlimited',
    },
    {
      name: 'Customer contacts :',
      count: 'Unlimited',
    },
    {
      name: 'Customer visits analytics :',
      count: '✔️',
    },
    {
      name: 'Invite and allow staff to manage the app :',
      count: '✔️',
    },
  ];
  select: boolean = false;
  planA: boolean = true;
  Subscription: boolean = false;
  success: boolean = false;
  rzp1: any;
  razorpay_payment_id: any;
  locationName: any;
  SubscriptionPlan!: any;
  SubscriptionPlanDetails: any;
  planDetails: any = [];
  expiredScreen2: any;
  cardError: boolean = true;
  selectAnyPlan: any;
  planDetailsSelect: any;
  merchantId: any;
  location_id: any;
  place_id: any;
  mobileNumber: any;
  merchantName: any;
  email: any;
  orderId: any;
  totalmount: any;
  sId: any;

  daysBetween: any;
  tokenDetails: any;
  tokenDetailsTrail: any;
  subdaysBetween: any;
  login1Loader: boolean = false;
  failed: boolean = false;
  SubscriptionPlan1: any;

  constructor(
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
    private service: ServicesService
  ) {}

  ngOnInit(): void {
    console.log('error', this.cardError);
    this.login1Loader = true;
    this.locationName = localStorage.getItem('locationName');
    this.Apis();
  }
  Apis() {
    this.service.merchantSubscriptionPlans().subscribe((result: any) => {
      console.log(result);
      this.login1Loader = false;

      this.SubscriptionPlanDetails = result.data;
      this.SubscriptionPlanDetails.forEach((planDetails: any) => {
        planDetails.isActive = false;
      });
      this.SubscriptionPlan1 = result.data.filter((plan: any) => {
        return plan.type == 'Monthly';
      });
      this.SubscriptionPlan = this.SubscriptionPlan1[0];
      console.log(this.SubscriptionPlan);
    });
    this.service.merchantDetails().subscribe((result: any) => {
      console.log(result);
      this.merchantId = result.logInData.merchantId;
      this.location_id = result.logInData.locations[0]._id;
      this.place_id = result.logInData.locations[0].place_id;
      this.tokenDetails = result.subscriptionData.statuse;
      this.tokenDetailsTrail = result.freeTrialData.status;
      this.subdaysBetween = this.calculateDaysBetween(
        result.subscriptionData.startDates,
        result.subscriptionData.endDates
      );
      this.daysBetween = this.calculateDaysBetween(
        result.freeTrialData.startDate,
        result.freeTrialData.endDate
      );
      console.log(this.daysBetween);
      this.mobileNumber = result.logInData.mobileNumber;
      this.merchantName = result.logInData.merchantName;
      this.email = result.logInData.email;
    });
  }
  calculateDaysBetween(startDate: string, endDate: string): number {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in time
    const diffInTime = end.getTime() - start.getTime();

    // Convert time difference from milliseconds to days
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

    return diffInDays;
  }
  toggleCard(card: any) {
    this.SubscriptionPlanDetails.forEach(
      (card: any) => (card.isActive = false)
    );
    this.planDetailsSelect = card;
    this.cardError = card.isActive;
    console.log('error', card);
    // Then, activate the selected card
    card.isActive = true;
    if (this.cardError == false) {
      this.selectAnyPlan = '';
    }
  }
  upgrade(day: any) {
    const data = this.SubscriptionPlanDetails.filter(
      (result: any) => result.per_day_price === day
    );
    console.log(data);
    this.planDetails = data;
    this.Subscription = true;
    this.planA = false;
  }
  continue() {
    if (this.cardError == true) {
      this.selectAnyPlan = 'please select any plan';
    } else {
      //  const data = this.planDetailsSelect.filter(
      //    (result: any) => result.per_day_price === day
      //  );
      // console.log(data);
      // this.planDetailsSelect;
      this.planDetails.push(this.planDetailsSelect);
      this.Subscription = true;
      this.expiredScreen2 = false;
    }
  }
  viewPlans() {
    this.expiredScreen2 = true;
    this.planA = false;
  }
  manage() {
    this.success = false;
    this.planA = true;
    this.Apis();
  }
  otherPlans() {
    this.expiredScreen2 = true;
    this.Subscription = false;
  }
  close() {
    this.Subscription = false;
    this.planA = true;
  }
  proccedToPay(amount: any, sId: any) {
    this.login1Loader = true;

    this.totalmount = amount;
    this.sId = sId;
    const orderData = {
      amount: amount.toString(),
      merchant_id: this.merchantId,
      subscription_id: sId,
      place_id: this.place_id,
      location_id: this.location_id,
      name: this.merchantName,
      mobileNumbe: this.mobileNumber,
      email: this.email,
    };

    this.service.createOrder(orderData).subscribe((res) => {
      this.login1Loader = false;

      this.orderId = res.data._id;
      console.log(res);
      let options = {
        key: `${environment.RAZOR_PAY_ID}`,
        currency: 'INR',
        amount: amount * 100,
        donationId: '',
        name: '',
        phoneNumber: '',
        description: '',
        contact: '',
        image: '',
        order_id: res.data._id,
        handler: function (response: any) {
          var event = new CustomEvent('payment.success', {
            detail: response,
            bubbles: true,
            cancelable: true,
          });
          window.dispatchEvent(event);
          // ref.handlePayment(response);
        },
        handler1: function (response: any) {
          var event = new CustomEvent('payment.failed', {
            detail: response,
            bubbles: true,
            cancelable: true,
          });
          window.dispatchEvent(event);
          // ref.handlePayment(response);
        },
        prefill: {
          name: '',
          email: '',
          contact: this.mobileNumber,
        },
        notes: {
          address: '',
        },
        theme: {
          color: '#3399cc',
        },
        modal: {
          ondismiss: () => {
            // this.donate = false;
          },
          // function () {
          //   console.log('Checkout form closed');
          // },
        },
      };
      options['handler'] = this.razorPaySuccessHandler.bind(this);
      // this.rzp1.on('payment.failed', this.paymentFailedHandler.bind(this));
      options['handler1'] = this.paymentFailedHandler.bind(this);
      // options.amount = this.Amount; //paise
      // options.donationId = this.DonateId; //paise
      // options.contact = this.DonateForm.value.phoneNumber; //paise
      this.rzp1 = new Razorpay(options);
      this.rzp1.open();
    });
  }

  public razorPaySuccessHandler(response: any) {
    console.log('payment-success', response);
    console.log('payment_id', response.razorpay_payment_id);

    this.razorpay_payment_id = response.razorpay_payment_id;
    localStorage.setItem('donatePaymentId', this.razorpay_payment_id);
    this.ngZone.run(() => {
      const payment = {
        amount: this.totalmount,
        merchant_id: this.merchantId,
        subscription_id: this.sId,
        place_id: this.place_id,
        payment_status: 'success',
        location_id: this.location_id,
        payment_id: this.razorpay_payment_id,
        order_id: this.orderId,
      };
      this.service.subscriptionPayment(payment).subscribe((response: any) => {
        console.log('sub-payment', response);
      });
      const paymentMerchant = {
        amount: this.totalmount,
        merchant_id: this.merchantId,
        subscription_id: this.sId,
        place_id: this.place_id,
        payment_status: 'success',
        location_id: this.location_id,
      };
      this.service
        .subscriptionPaymentMerchant(paymentMerchant)
        .subscribe((response: any) => {
          console.log('sub-Merc-payment', response);
        });
      this.success = true;
      this.Subscription = false;
    });
    // .then();

    this.cd.detectChanges();
    // document.getElementById('razorpay-response').style.display = 'block';
  }
  public paymentFailedHandler(response: any) {
    console.log('payment-faied', response);
    // console.log('payment_id', response.razorpay_payment_id);

    // this.razorpay_payment_id = response.razorpay_payment_id;
    // localStorage.setItem('donatePaymentId', this.razorpay_payment_id);
    this.ngZone.run(() => {
      const paymentMerchant = {
        amount: this.totalmount,
        merchant_id: this.merchantId,
        subscription_id: this.sId,
        place_id: this.place_id,
        payment_status: 'failed',
        location_id: this.location_id,
      };
      this.service
        .subscriptionPaymentMerchant(paymentMerchant)
        .subscribe((response: any) => {
          console.log('sub-Merc-payment', response);
        });
      this.failed = true;
      this.Subscription = false;
    });
    // .then();

    this.cd.detectChanges();
    // document.getElementById('razorpay-response').style.display = 'block';
  }
}
