<app-navbar></app-navbar>


<div class="main">
   <div class="push_button_section">
      <div class="container pt-md-5">
        <div class="row">
            <div class="col-md-6">
          <div class="button_push_section">
              <h5>The simplest and fastest software app to <span>Google reviews</span> for your local business</h5>
              <p>Maintain a winning reputation, engage digitally, and deliver an exceptional customer experience - all from one intuitive platform.</p>
              <div class="d-flex">
                 <!-- <div class="form_Section">
                    <input placeholder="Enter e-mail">
                 </div> -->
                 <div class="submit_section">
                    <button class="cursor" disabled>Launching soon</button>
                 </div>
              </div>
          </div>
            </div>
            <div class="col-md-6"></div>
        </div>
         
      </div>
   </div>


   <div class="find_your_industry_section pt-md-5">
      <div class="industry_section container">

        <button class="cursor">Industries</button>
        <h4>Find your Industry</h4>
         <div class="row">
            <div class="col-md-3" *ngFor="let details of cardsDetails">
                <div class="card mb-4">
                    <div class="card-body">
                        <img [src]="details.Image"  >
                         <h3>{{details.name}}</h3>
                         <a>Learn more  > </a>
                    </div>
                </div>
            </div>
         </div>
      </div>
   </div>


   <div class="win_every_section pt-md-5 mt-md-4">
       <div class="repeat_section">
         
           <p>The simplest and easiest software app created for local businesses to grow.</p>

           <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/reviews_image.svg">
                </div>
                <div class="col-md-6 mt-md-4">
                  <button class="cursor features">Features</button>
                    <h5>Reviews</h5>
                    <ul>
                      <li>Drive more reviews and website traffic. </li>
                      <li>Grow < Business> reputation with Continuous Google Reviews. </li>
                      <li>Easily submit reviews through a user-friendly interface, making it simple for customers to share their thoughts. </li>
                      <li>Implement a detailed rating system that goes beyond stars, allowing customers to provide nuanced feedback on different aspects of their experience. </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Get started</button>
                      <button class="learn ml-5">Learn more ></button>
                    </div>
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    <button class="cursor features">Features</button>
                    <h5>Referrals</h5>
                    <ul>
                      <li>Get more leads with automated referral campaigns. </li>
                      <li>Send automated and measurable referral campaigns at scale in just a few clicks. </li>
                      <li>Stay in the loop with our referral tracking feature. Monitor the status of your referrals and see the impact of your network. </li>
                      <li>Familiarize yourself with our referral program terms and conditions to ensure a smooth and rewarding experience. </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Get started</button>
                      <button class="learn ml-5">Learn more ></button>
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/refeerals.svg">

                
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/customers.svg">
                </div>
                <div class="col-md-6 mt-md-4">
                  <button class="cursor features">Features</button>
                    <h5>Customers</h5>
                    <ul>
                      <li>Increase your reviews, increase your customer visits and increase your sales. </li>
                      <li>Access special promotions, discounts, and offers available exclusively to customers who engage with our bulletin. </li>
                      <li>Access helpful tips, tutorials, and guides to maximize the use of our app. </li>
                    
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Get started</button>
                      <button class="learn ml-5">Learn more ></button>
                    </div>
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    <button class="cursor features">Features</button>
                    <h5>Auto-Pilot</h5>
                    <p class="auto">Acquire new customers using referrals system to grow your sales. Bring Back your customers with Auto pilot.</p>
                    <ul>
                      <li>Acquire new customers using referrals system to grow your sales. </li>
                      <li>Bring Back your customers with Auto pilot.</li>
                      <li>Bring Back your customers with Auto pilot.</li>
                      <li>Bring Back your customers with Auto pilot.</li>
                    
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Get started</button>
                      <button class="learn ml-5">Learn more ></button>
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/refeerals.svg">

                
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/sms.svg">
                </div>
                <div class="col-md-6 mt-md-4">
                  <button class="cursor features">Features</button>
                    <h5>SMS Marketing</h5>
                    <ul>
                      <li>Reach customers instantly with personalized SMS messages, fostering immediate engagement. </li>
                      <li>Send gentle reminders to customers to provide feedback, increasing response rates. </li>
                      <li>Receive real-time alerts for critical feedback, enabling swift response and issue resolution.</li>
                      <li>Use SMS marketing to show customers that their opinions matter, contributing to enhanced satisfaction.</li>
                    
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Get started</button>
                      <button class="learn ml-5">Learn more ></button>
                    </div>
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    <button class="cursor features">Features</button>
                    <h5>Customer Support</h5>
                    
                    <ul>
                      <li>Easily track the status of your support inquiries through a ticketing system.</li>
                      <li>Stay informed about the progress of your requests and receive timely updates.</li>
                      <li>Access customer support through various channels, including chat, email, and phone.</li>
                      <li>We value your feedback and use it to enhance the customer support experience for all users.</li>
                    
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Get started</button>
                      <button class="learn ml-5">Learn more ></button>
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/customer-support.svg">

                
                </div>
              </div>
           </div>
               <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/locatios.svg">
                </div>
                <div class="col-md-6 mt-md-4">
                  <button class="cursor features">Features</button>
                    <h5>Locations</h5>
                    <ul>
                      <li>This tool empowers you to organize and analyze customer feedback based on different locations, helping you gain deeper insights into the unique needs and preferences of your customers across various areas. </li>
                      <li>Manage all locations at one place. </li>
                      <li>Manage all locations at one place. </li>
                     
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Get started</button>
                      <button class="learn ml-5">Learn more ></button>
                    </div>
                </div>
              </div>
           </div>
       </div>


       <div class="secure_section container my-md-4">
         <div class="section_secure">
            <div class="d-md-flex">
               <div class="leads_today mt-md-4">
                   <h4>Secure more leads today.</h4>
                   <p>Learn how Automatic’s suite of tools can help your business grow and scale today.</p>
                    <div class="demo_btn">
        <button class="cursor">Get a Demo</button>
        </div>
               </div>
               <div class="today_section_images ml-auto">
                  <img src="../../../assets/images/find-leads-generate-sales-leads 1.svg">
               </div>
            </div>
         </div>
       </div>


        
      
   </div>
</div>
<app-footer></app-footer>