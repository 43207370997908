import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  cardsDetails: any[] = [
    {
      name: 'Restaurant',
      Image: '../../../assets/images/restaurant.svg',
    },
    {
      name: 'Dentals',
      Image: '../../../assets/images/dental.svg',
    },
    {
      name: 'Beauty Parlour',
      Image: '../../../assets/images/beauty.svg',
    },
    {
      name: 'Skin care',
      Image: '../../../assets/images/skincare.svg',
    },
    {
      name: 'Jewellers',
      Image: '../../../assets/images/Jewellers.svg',
    },
    {
      name: 'Saloons',
      Image: '../../../assets/images/saloons.svg',
    },
    {
      name: 'Car Repairs',
      Image: '../../../assets/images/car.svg',
    },
    {
      name: 'Footwear',
      Image: '../../../assets/images/footwear.svg',
    },
    {
      name: 'Gyms',
      Image: '../../../assets/images/gyms.svg',
    },
    {
      name: 'Pet Shops',
      Image: '../../../assets/images/pets.svg',
    },
    {
      name: 'Furniture Showroom',
      Image: '../../../assets/images/furinture.svg',
    },
    {
      name: 'CA Firms',
      Image: '../../../assets/images/ca.svg',
    },
    {
      name: 'Legal Firms',
      Image: '../../../assets/images/legal.svg',
    },
    {
      name: 'Driving Schools',
      Image: '../../../assets/images/pets.svg',
    },
    {
      name: 'Garment Shops',
      Image: '../../../assets/images/germent.svg',
    },
    {
      name: 'Cafe',
      Image: '../../../assets/images/cafe.svg',
    },
    {
      name: 'Pubs',
      Image: '../../../assets/images/pub.svg',
    },
    {
      name: 'Hospitals',
      Image: '../../../assets/images/hosptial.svg',
    },
    {
      name: 'Museum',
      Image: '../../../assets/images/musem.svg',
    },
    {
      name: 'PGs',
      Image: '../../../assets/images/pg.svg',
    },
  ];
  panelOpenState = false;
  constructor() {}

  ngOnInit(): void {}
}
