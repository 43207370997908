import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-commonbussiesstype',
  templateUrl: './commonbussiesstype.component.html',
  styleUrls: ['./commonbussiesstype.component.css'],
})
export class CommonbussiesstypeComponent implements OnInit {
  cardsDetails: any[] = [
    {
      name: '10x',
      details: 'More Google reviews',
      Image: '../../../assets/images/google-im.svg',
    },
    {
      name: '50%',
      details: 'More leads and enquiries',

      Image: '../../../assets/images/revieews-In.svg',
    },
    {
      name: '14.3%',
      details: 'High Reputation for local business',
      Image: '../../../assets/images/traffic.svg',
    },
  ];
  steepper: any[] = [
    {
      count: 1,
      header:
        'Download Localfirst app and Set up Google Reviews system in minutes',
      details: '',
      view: true,
    },
    {
      count: 2,
      header: 'Enter customer phone number, send review request',
      details: '',
      view: true,
    },
    {
      count: 3,
      header: 'Get Google review in minutes and respond from the app directly',
      details: '',
      view: true,
    },
    {
      count: 4,
      header: 'View and manage all reviews from the app itself',
      details: '',
      view: false,
    },
  ];
  currentRouteName: any;
  routerName: any;
  constructor(private router: Router, private route: ActivatedRoute) {
    console.log(this.route.snapshot.params);
    this.routerName = this.route.snapshot.params.name;
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRouteName = this.getRouteName(event.urlAfterRedirects);
        console.log('Current Route Name:', this.currentRouteName);
        this.routerName = this.currentRouteName;
      });
  }
  getRouteName(url: string): string {
    const segments = url.split('/');
    return segments[segments.length - 1];
  }
}
