import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-earnwith-automatik',
  templateUrl: './earnwith-automatik.component.html',
  styleUrls: ['./earnwith-automatik.component.css'],
})
export class EarnwithAutomatikComponent implements OnInit {
  cardsDetails: any[] = [
    {
      name: '1',
      details: 'Download Localfirst Sale Rep app',
    },
    {
      name: '2',
      details: 'Become Localfirst Local Sale Rep in minutes',
    },
    {
      name: '3',
      details:
        'Vist local businesses in your city and tell them about Localfirst app',
    },
    {
      name: '4',
      details:
        'Earn INR 2000 for each local bussiness that sign up for Localfirst app',
    },
  ];
  earn: any[] = [
    {
      name: '1',
      details: '3-4 hours a day time',
    },
    {
      name: '2',
      details: 'A smart phone and a Bike/Car',
    },
    {
      name: '3',
      details: 'Basic Degree How to use a smartphone',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
