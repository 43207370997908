import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscriptionexpired',
  templateUrl: './subscriptionexpired.component.html',
  styleUrls: ['./subscriptionexpired.component.css'],
})
export class SubscriptionexpiredComponent implements OnInit {
  freeTrail: any = [
    {
      name: 'Locations :',
      count: 1,
    },
    {
      name: 'Review requests :',
      count: '500/month',
    },
    {
      name: 'Referral requests :',
      count: '500/month',
    },
    {
      name: 'Referral Leads :',
      count: 'Unlimited',
    },
    {
      name: 'Customer contacts :',
      count: 'Unlimited',
    },
    {
      name: 'Customer visits analytics :',
      count: '✔️',
    },
    {
      name: 'Invite and allow staff to manage the app :',
      count: '✔️',
    },
  ];
  expiredScreen1: boolean = true;
  expiredScreen2: boolean = false;
  SubscriptionModel: any = false;
  success: boolean = false;
  constructor() {}

  ngOnInit(): void {}
  viewPlans() {
    this.expiredScreen1 = false;
    this.expiredScreen2 = true;
  }
  reActive() {
    this.expiredScreen1 = false;
    this.SubscriptionModel = true;
  }
  continue() {
    this.expiredScreen2 = false;
    this.SubscriptionModel = true;
  }
  proccedToPay() {
    this.success = true;
    this.SubscriptionModel = false;
  }
}
