
<app-navbar></app-navbar>
<div class="container" *ngIf="expiredScreen1">
        <div class="header">
            <button class="dropdown">PK Luxury Saloon ⌄</button>
        </div>
        <p class="expired-message">Your subscription for <span class="location-name">Location Name</span> has cancelled</p>
        <p class="alert-message">
            <span>ALERT:</span> You won’t be able to request for new Google reviews and Referrals via our app. 
            Please upgrade to premium subscription to continue using our software.
        </p>
        <div class="plan-card">
            <h3>Premium Plan</h3>
            <p class="price">₹3999/month</p>
            <h4>Premium Plan Benefits</h4>
            
            <div class="d-flex justify-content-between" *ngFor="let plan of freeTrail">
                <div class="name">
{{ plan.name}}
                </div>
                <div class="count">
{{plan.count}}
                </div>
            </div>
            <!-- <a href="#" class="manage-subscription">Manage Subscription</a> -->
            <button class="btn-reactivate" (click)="reActive()">Reactivate Plan Now</button>
            <a  class="view-plans" (click)="viewPlans()">View Other Plans</a>
        </div>
        <div class="help-card">
            <p>Need help?</p>
            <a href="#">Contact us</a>
        </div>
    </div>

    <div class="container mt-4" *ngIf="expiredScreen2">
        <p class="select-message">Please select a subscription for <span class="location-name">Location Name</span> to continue</p>
        
        <div class="plan-card selected">
            <h3>Monthly</h3>
            <p class="price">₹3999</p>
            <p class="description">You're spending ₹3999 per month only to grow online reputation and to get more local referrals and manage customer contacts for </p>
        </div>
        
        <div class="recommand">Recommended for your business</div>
        
        <div class="plan-card">
            <h3>Six Months <span class="original-price">₹23,990</span><br> <span class="price">₹21,000</span></h3>
            <p class="description">You're spending ₹3500 per month only to grow online reputation and to get more local referrals and manage customer contacts for  </p>
            <!-- <input type="radio" name="subscription" value="six-months"> -->
        </div>
        
        <div class="plan-card">
            <h3>Yearly <span class="original-price">₹47,990</span><br><span class="price">₹36,000</span></h3>
            <p class="description">You're spending ₹3000 per month only to grow online reputation and to get more local referrals and manage customer contacts for  </p>
            <!-- <input type="radio" name="subscription" value="yearly"> -->
        </div>
        
        <div class="help">
            <a href="#" class="need-help">Need Help</a>
        </div>
        
        <button class="btn-continue" (click)="continue()">Continue with Monthly</button>
    </div>

    
     <div class="container" *ngIf="SubscriptionModel">
        <div class="trail-model">
        <div class="header">
            <div class="indicator"></div>
        </div>
        <h2>Monthly Subscription plan</h2>
        <div class="order-summary">
            <h3>Order summary</h3>
            <p>You have selected one month subscription type for <span class="location">Location</span></p>
            <div class="summary-details">
                <div class="detail-row">
                    <span>Subscription type</span>
                    <span class="black">One month</span>
                </div>
                <div class="detail-row">
                    <span>Price</span>
                    <span>₹3999</span>
                </div>
                <div class="detail-row">
                    <span>GST and Tax</span>
                    <span>₹719.82</span>
                </div>
                <div class="detail-row total">
                    <span>Total payable amount</span>
                    <span class="black">₹4719</span>
                </div>
            </div>
            <p class="auto-renew">Your subscription will auto renew until you cancel</p>
            <button class="btn-proceed cursor" (click)="proccedToPay()">Proceed to Pay ₹4719</button>
            <a href="#" class="view-plans">Other Subscription Plans</a>
        </div>
    </div>
    </div> 

          <div class="container p-s" *ngIf="success">
        <h1>Payment Successful for <span class="location-name">Location_name</span></h1>
        <div class="amount">₹4719.00</div>
        <p class="recipient">Paid to</p>
        <p class="recipient">Localfirst software solutions private limited</p>
        <div class="transaction-id">
            Transaction ID: <span>asdfghj123456789</span>
        </div>
        <p class="date-time">11:00 AM, 20th May 2022</p>
        <p class="message">Now you can continue to request reviews and referrals from our Localfirst app</p>
        <p class="message1">Login to the LocalFirst app now</p>
        <button class="btn-open-app">Open the app</button>
        <a href="#" class="manage-subscription">Manage subscription</a>
        <div class="help">
            <a href="#" class="need-help">Need Help</a>
        </div>
    </div>