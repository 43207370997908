import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  showDropdown = false;
  solutionsArray: any = [
    {
      name: 'Customer contacts',
      // link: '/',
    },
    {
      name: 'Team management',
      // link: '/Team-management',
    },
    {
      name: 'Referrals',
      // link: '/Referrals',
    },

    {
      name: 'SMS Marketing',
      // link: '/Sms-marketing',
    },

    {
      name: 'Autopilot',
      // link: '/Autopliot',
    },
  ];
  bussinesTypes_A: any = [
    {
      name: 'Overview',
    },
    {
      name: 'Auto OEM',
    },
    {
      name: 'Auto Body Shop',
    },
  ];
  bussinesTypes_H: any = [
    {
      name: 'Overview',
    },
    {
      name: 'Medical Spa',
    },
    {
      name: 'Dental',
    },
  ];
  bussinesTypes_HOME: any = [
    {
      name: 'Overview',
    },
    {
      name: 'HVAC',
    },
    {
      name: 'Plumbing',
    },
  ];
  bussinesTypes_Reatail: any = [
    {
      name: 'Overview',
    },
    {
      name: 'Jewelry',
    },
    {
      name: 'Furniture',
    },
    {
      name: 'Appliance',
    },
    {
      name: 'Mattress',
    },
  ];
  cardsDetails: any[] = [
    {
      name: 'Restaurant',
      Image: '../../../assets/images/restaurant.svg',
    },
    {
      name: 'Dental Clinic',
      Image: '../../../assets/images/dental.svg',
    },
    {
      name: 'Beauty Parlour',
      Image: '../../../assets/images/beauty.svg',
    },
    {
      name: 'Skin Care Clinic',
      Image: '../../../assets/images/skincare.svg',
    },
    {
      name: 'Jewellery Shop',
      Image: '../../../assets/images/Jewellers.svg',
    },
    {
      name: 'Hair Saloon',
      Image: '../../../assets/images/saloons.svg',
    },
    {
      name: 'Car Repair Shop',
      Image: '../../../assets/images/car.svg',
    },
    {
      name: 'Footwear Shop',
      Image: '../../../assets/images/footwear.svg',
    },
    {
      name: 'Gym',
      Image: '../../../assets/images/gyms.svg',
    },
    {
      name: 'Pet Shop',
      Image: '../../../assets/images/pets.svg',
    },
    {
      name: 'Furniture Showroom',
      Image: '../../../assets/images/furinture.svg',
    },
    {
      name: 'CA Firm',
      Image: '../../../assets/images/ca.svg',
    },
    {
      name: 'Legal Firm',
      Image: '../../../assets/images/legal.svg',
    },
    {
      name: 'Driving School',
      Image: '../../../assets/images/pets.svg',
    },
    {
      name: 'Garment Shop',
      Image: '../../../assets/images/germent.svg',
    },
    {
      name: 'Cafe',
      Image: '../../../assets/images/cafe.svg',
    },
    {
      name: 'Bar and Pub',
      Image: '../../../assets/images/pub.svg',
    },
    {
      name: 'Hospital',
      Image: '../../../assets/images/hosptial.svg',
    },
    {
      name: 'Museum',
      Image: '../../../assets/images/musem.svg',
    },
    {
      name: 'Hostel',
      Image: '../../../assets/images/pg.svg',
    },
  ];
  solutions: boolean = false;
  bussiness_type: boolean = false;
  token: any;
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
  }
  solutionBtn() {
    this.solutions = !this.solutions;
    this.bussiness_type = false;
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/merchants/login']);
  }
  bussiness_typeBtn() {
    this.bussiness_type = !this.bussiness_type;
    this.solutions = false;
  }
  navigate(item: any) {
    this.router.navigate([`${item.split(' ').join('-')}`]);
    this.bussiness_type = false;
  }
}
