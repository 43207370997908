<app-navbar></app-navbar>
<div class="main">
     
 <div class="push_button_section">
      <div class="container pt-md-5">
        <div class="row">
            <div class="col-md-6">
          <div class="button_push_section">
                           <h5>The easiest way to request Google Reviews</h5>

              <br>
              <h4>Give it a try for free with 14-days free trial</h4>
              <br>
              <div class="d-flex">
                 <!-- <div class="form_Section">
                    <input placeholder="Enter e-mail">
                 </div> -->
                 <div class="submit_section">
                                    <button class="cursor" disabled>Launching soon</button>

                 </div>

              </div>
              <h4 class="mt-5">"This software app is a blessing for local businesses"</h4>

          </div>
            </div>
            <div class="col-md-6 type_img">
               <img src="../../../assets/images/review-1.svg">
            </div>
        </div>
         
      </div>
   </div>




   <app-footer></app-footer>
<!-- <div class="how_it_works mt-md-5 pt-md-4">
<div class=" beat_your_competetion container"  >

      
        <h4>How it works?</h4>
        <p>The simplest and easiest software app created for local businesses to grow..</p>
        </div>


        <div class="stepper_class mt-md-5 pt-md-3">
           <div class="d-flex justify-content-start stepper" *ngFor="let details of steepper">
              <div class="counter_section mr-4">
                 <p>{{details.count}}</p>
                 <span *ngIf="details.view"></span>
              </div>
              <div class="counter_text_section">
                 <h3>{{details.header}}</h3>
                 <p>{{details.details}}</p>
              </div>
           </div>
        </div>
</div>
     
   

   <div class=" pt-md-5">
      <div class=" beat_your_competetion container"  >

        
        <h4>The fastest and most easiest way to request Google reviews from customers</h4>
       
        </div>
         <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/reviews-1.svg">
                </div>
                <div class="col-md-6 mt-md-4">
                  
                    <h5>Request for a review</h5>
                    <ul>
                       <li>Simply enter customer phone number </li>
                      <li>Choose Request for Google Review </li>
                      <li>Click on Send Review Request</li>
                      <li>That's it. The app takes care of everything else </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                   
                    </div>
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    
                    <h5>App Sends SMS with Review Link</h5>
                    <ul>
                      <li>Customers gets automatic SMS with review link </li>
                      <li>Message sent to the customer at the right time </li>
                      <li>Customer writes the review at their convenience </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                   
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/reviews-2.svg">

                
                </div>
              </div>
           </div>
             <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/reviews-3.svg">
                </div>
                <div class="col-md-6 mt-4">
                 
                    <h5>Customer Posts review with one click</h5>
                    <ul>
                      <li>With one click, customer can write review for your local business</li>
                      <li>Easiest way for customer to leave a Google review </li>
                      <li>No QR code scanning. No need to ask manaully </li>
                      <li>No need to find your local business on Google to write review</li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                   
                    </div>
                </div>
              </div>
           </div>
         <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6">
                  
                    <h5>Push Notifications</h5>
                    <ul>
                      <li>Get notified of new reviews </li>
                      <li>Stay alert with negative reviews to respond quickly </li>
                      <li>Experience the flow of new reviews every day </li>
                      
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                   
                    </div>
                </div>
                <div class="col-md-6 mt-4">
                  
                  <img src="../../../assets/images/reque-reviews-3.svg">

                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row  ">
                <div class="col-md-6 mt-4">
                  <img src="../../../assets/images/reviews-5.svg">

                    
                </div>
                <div class="col-md-6 ">

               
                    <h5>View all Reviews and Reply from the app</h5>
                    <ul>
                      <li>Track all reviews at one palce </li>
                      <li>Quickly resond to new reviews with templates </li>
                      <li>Read and monitor all reviews without opening your Google Business Profile </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                   
                    </div>
                </div>
              </div>
           </div>
           
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6">
                  
                    <h5>Review analytics</h5>
                    <ul>
                       <li>Track all your reviews from the app </li>
                      <li>View Reviews Growth </li>
                      <li>Easily monitor your local business from the app </li>
                     
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                   
                    </div>
                </div>
                <div class="col-md-6 mt-4">
                  <img src="../../../assets/images/reviews-7.svg">
                 
                </div>
              </div>
           </div>
        </div>
         <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6 mt-md-4">
                  <h2>Get visablity of Negative Reviews</h2>
                 
                    
                    <div class="started_button">
                      <button class="learn mt-md-4">Take quick actions and save your business reputation</button>
                    </div>
                </div>
                <div class="col-md-6  ">
              
                  <img src="../../../assets/images/view-1.svg" class="value-img">
                    
                </div>
              </div>
           </div>
         <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6">
                  <img src="../../../assets/images/view-2.svg" class="value-img">
                </div>
                <div class="col-md-6 mt-md-4">
              
                    <h2>Track weekly new reviews growth</h2>
                   
                    
                    <div class="started_button">
                      <button class="learn mt-md-4">Take quick actions and save your business reputation</button>
                    </div>
                </div>
              </div>
           </div>
   <div class=" pt-md-5">
      <div class=" beat_your_competetion container"  >

        
        <h4>How Google Reviews Change your local business</h4>
       
        </div>
         <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/values-1.svg" class="value-img">
                </div>
                <div class="col-md-6 mt-md-4">
              
                    <h2>Be found before your competitors</h2>
                    <p class="auto">More reviews improves your local SEO rankings. It helps you get more visibility
                    in the Google search</p>
                    
                    <div class="started_button">
                      <button class="learn mt-4">Beat your local competitors </button>
                    </div>
                </div>
              </div>
           </div>
         <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                  <h2>Get more positive reviews visible</h2>
                    <p class="auto">Continous reviews increases more posistive reviews. Local business
                    with posisitve reviews gets picked by your potentinal customers</p>
                    
                    <div class="started_button">
                      <button class="learn mt-4">Build trust and give confidence to customers</button>
                    </div>
                </div>
                <div class="col-md-6  ">
              
                  <img src="../../../assets/images/values-2.svg" class="value-img">
                    
                </div>
              </div>
           </div>
         <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/values-3.svg" class="value-img">
                </div>
                <div class="col-md-6 mt-md-4">
              
                    <h2>Get more enquiries, leads and acquire new customers</h2>
                    <p class="auto">Reviews helps you get more leads, more enquiries and more revenue for your local business</p>
                    
                    <div class="started_button">
                      <button class="learn mt-4">Don't hope for new customers. Systematically get new customers
                      with continous reviews</button>
                    </div>
                </div>
              </div>
           </div>
        
            
  <div class=" pt-md-5">
      <div class="people_section_problem container">

        <button class="cursor">Industries</button>
        <h4>A simple SaaS app specifically built to help local business to grow their Google reviews</h4>
         <div class="row">
            <div class="col-md-4" *ngFor="let details of cardsDetails">
                <div class="card mb-md-4">
                    <div class="card-body">
                     <div class="text-center">
<img [src]="details.Image"  >
                         <h3>{{details.name}}</h3>
                         <p>{{details.details}}</p>
                     </div>
                        
                    </div>
            </div>
         </div>
      </div>
   </div>
          
                </div>
      
        </div>
</div>
<app-footer></app-footer> -->