import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  constructor(private http: HttpClient, private router: Router) {}

  Login(data: any) {
    const url = `${environment.MERCHANT_URL}/merchant/webview/login`;
    return this.http.post<any>(url, data);
  }
  verifyOtp(data: any) {
    const url = `${environment.MERCHANT_URL}/merchant/webview/login/verify-otp`;
    return this.http.post<any>(url, data);
  }
  createOrder(data: any) {
    const url = `${environment.MERCHANT_SUB_URL}/subscription/order`;
    return this.http.post<any>(url, data);
  }
  subscriptionPayment(data: any) {
    const url = `${environment.MERCHANT_SUB_URL}/subscription/payment`;
    return this.http.post<any>(url, data);
  }
  subscriptionPaymentMerchant(data: any) {
    const url = `${environment.MERCHANT_SUB_URL}/subscription/merchant`;
    return this.http.post<any>(url, data);
  }
  merchantDetails() {
    const url = `${environment.MERCHANT_URL}/merchant/token-data`;
    return this.http.get<any>(url);
  }
  merchantSubscriptionDetails() {
    const url = `${environment.MERCHANT_SUB_URL}/subscription/subscription-details`;
    return this.http.get<any>(url);
  }
  merchantSubscriptionPlans() {
    const url = `${environment.MERCHANT_SUB_URL}/subscription`;
    return this.http.get<any>(url);
  }
  getToken() {
    return localStorage.getItem('token') || '';
  }
  logOut() {
    if (localStorage.getItem('token') != null) {
      localStorage.clear();
      this.router.navigate(['/merchants/login']);
    }
  }
}
