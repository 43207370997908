<app-navbar></app-navbar>
<div class="main">
 
 <div class="push_button_section">
      <div class="container pt-md-5">
        <div class="row">
            <div class="col-md-6">
          <div class="button_push_section">
              <h5>Visit local businesses in your city and earn upto 60,000 a month</h5>
              <p>Become Local Sales Rep for Localfirst</p>
              <div class="d-flex">
                 <!-- <div class="form_Section">
                    <input placeholder="Enter e-mail">
                 </div> -->
                 <div class="submit_section">
                    <button class="cursor" disabled>Launching soon</button>
                 </div>
              </div>
          </div>
            </div>
            <div class="col-md-6">
                  <!-- <img src="../../../assets/images/h-1.svg" class="h-1"> -->

            </div>
        </div>
         
      </div>
   </div>

  <div class=" pt-md-5 started">
      <div class="people_section_problem container">

        <h4>How to get started</h4>
         <div class="row">
            <div class="col-md-3" *ngFor="let details of cardsDetails">
                <div class="card mb-md-4">
                    <div class="card-body">
                     <div class="text-center">
                         <h3>{{details.name}}</h3>
                         <p>{{details.details}}</p>
                         <!-- <a>Learn more  > </a> -->
                     </div>
                        
                    </div>
                </div>
            </div>
         </div>
      </div>
   </div>


 
     
   <div class="container">
      <div class="work_anytime">
         <h5>Work anytime from anywhere</h5>
         <ul>
            <li>No job interview</li>
            <li>No targets</li>
            <li>No timings</li>
         </ul>
      </div>
   </div>
   <div class=" pt-md-5">
      <div class=" beat_your_competetion container"  >

        <h4>Onboard ten local businesses a month and you can earn INR 60,000 a month ( $750 / month)</h4>
        </div>
         
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    <h5>Training</h5>
                   <p class="auto">Get your in-app training from your home in less than a week and be ready to be the Sales rep</p>
                    <div class="started_button">
                      <button class="get">Pick the places</button>
                      <!-- <button class="learn ml-5">Learn more ></button> -->
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/e-1.svg">

                
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    <h5>Places</h5>
                   <p class="auto">Find local businesses  in your city of various categories to start visiting</p>
                    <div class="started_button">
                      <button class="get">Pick the places</button>
                      <!-- <button class="learn ml-5">Learn more ></button> -->
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/e-2.svg">

                
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    <h5>Leads</h5>
                   <p class="auto"> Add places to your leads and make a visit. Directions provided in the app itself</p>
                    <div class="started_button">
                      <button class="get">Add to leads</button>
                      <!-- <button class="learn ml-5">Learn more ></button> -->
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/e-3.svg">

                
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    <h5>Visit</h5>
                   <p class="auto">Visit your leads within 15-days for a potential earnings</p>
                    <div class="started_button">
                      <button class="get">Add to leads</button>
                      <!-- <button class="learn ml-5">Learn more ></button> -->
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/e-4.svg">

                
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    <h5>Freetrials</h5>
                   <p class="auto">Give the demo of LocalFirst software app to the business owners and make them activate their 30-days free trial</p>
                    <div class="started_button">
                      <button class="get">Convert to freetrails</button>
                      <!-- <button class="learn ml-5">Learn more ></button> -->
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/e-5.svg">

                
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    <h5>Activate Subscription</h5>
                   <p class="auto">If the local business activate their premium subscription after 30-days-- you will earn whatever the business pays first month ( INR 2000-3000) per conversion.</p>
                    <div class="started_button">
                      <button class="get">Convert to Subscription</button>
                      
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/e-6.svg">

                
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    <h5>Track your earnings</h5>
                   <p class="auto">If the local business activate their premium subscription after 30-days-- you will earn whatever the business pays first month ( INR 2000-3000) per conversion.</p>
                    <div class="started_button">
                      <button class="get">Convert to Subscription</button>
                      <!-- <button class="learn ml-5">Learn more ></button> -->
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/e-7.svg">

                
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    <h5>Earnings</h5>
                   <p class="auto">Always know how much you are earning. Keep track of your monthly earnings</p>
                    <div class="started_button">
                      <button class="get">view earnings</button>
                      <!-- <button class="learn ml-5">Learn more ></button> -->
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/e-8.svg">

                
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    <h5>Withdraw earnings</h5>
                   <p class="auto">Withdraw your earnings every two weeks and repeat.</p>
                    <div class="started_button">
                      <button class="get">Withdraw earnings</button>
          
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/e-9.svg">

                
                </div>
              </div>
           </div>






         <!-- <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/reque-reviews-3.svg">
                </div>
                <div class="col-md-6 mt-md-4">
                  <button class="cursor features">Features</button>
                    <h5>Push Notifications</h5>
                    <ul>
                      <li>Drive more reviews and website traffic. </li>
                      <li>Grow < Business> reputation with Continuous Google Reviews. </li>
                      <li>Easily submit reviews through a user-friendly interface, making it simple for customers to share their thoughts. </li>
                      <li>Implement a detailed rating system that goes beyond stars, allowing customers to provide nuanced feedback on different aspects of their experience. </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                      
                    </div>
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    <button class="cursor features">Features</button>
                    <h5>View review & replyS</h5>
                    <ul>
                      <li>Get more leads with automated referral campaigns. </li>
                      <li>Send automated and measurable referral campaigns at scale in just a few clicks. </li>
                      <li>Stay in the loop with our referral tracking feature. Monitor the status of your referrals and see the impact of your network. </li>
                      <li>Familiarize yourself with our referral program terms and conditions to ensure a smooth and rewarding experience. </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                     
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/reque-reviews-4.svg">

                
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/reque-reviews-5.svg">
                </div>
                <div class="col-md-6 mt-md-4">
                  <button class="cursor features">Features</button>
                    <h5>Review analytics</h5>
                    <ul>
                      <li>Drive more reviews and website traffic. </li>
                      <li>Grow < Business> reputation with Continuous Google Reviews. </li>
                      <li>Easily submit reviews through a user-friendly interface, making it simple for customers to share their thoughts. </li>
                      <li>Implement a detailed rating system that goes beyond stars, allowing customers to provide nuanced feedback on different aspects of their experience. </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                     
                    </div>
                </div>
              </div>
           </div>
        </div> -->
   <!-- <div class=" pt-md-5">
      <div class=" beat_your_competetion container"  >

        <button class="cursor">Values</button>
        <h4>Values they get</h4>
        <p>Win every lead, and drive repeat business. Industries Win every lead, and drive repeat business. Industries Win every lead, and drive repeat business..</p>
        </div>
         <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/values-1.svg" class="value-img">
                </div>
                <div class="col-md-6 mt-md-4">
              
                    <h2>Be found & beat your competition</h2>
                    <p class="auto">Provide actionable insights and recommendations based on customer feedback to drive improvements.</p>
                    
                    <div class="started_button">
                      <button class="learn mt-4">Learn more ></button>
                    </div>
                </div>
              </div>
           </div>
         <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6 mt-md-4">
                  <h2>Get more positive reviews visible</h2>
                    <p class="auto">Implement a rating system, typically on a scale of 1 to 5 stars, to allow users to quickly express their overall satisfaction level.</p>
                    
                    <div class="started_button">
                      <button class="learn mt-4">Learn more ></button>
                    </div>
                </div>
                <div class="col-md-6  ">
              
                  <img src="../../../assets/images/values-2.svg" class="value-img">
                    
                </div>
              </div>
           </div>
         <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/values-3.svg" class="value-img">
                </div>
                <div class="col-md-6 mt-md-4">
              
                    <h2>Get more enquiries and more footfalls</h2>
                    <p class="auto">Provide the ability for customers to leave feedback in real-time, allowing businesses to address issues promptly.</p>
                    
                    <div class="started_button">
                      <button class="learn mt-4">Learn more ></button>
                    </div>
                </div>
              </div>
           </div>
            
              <div class="secure_section container my-md-4">
         <div class="section_secure">
            <div class="d-flex">
               <div class="leads_today lead_reiews mt-md-4">
                   <h4>Win every lead, and drive repeat business.</h4>
                   <p>Win every lead, and drive repeat business. Industries Win every lead, and drive repeat business. Industries Win every lead, and drive repeat business.</p>
                    <div class="demo_btn">
        <button class="cursor">Get a Demo</button>
        </div>
               </div>
               <div class="business_section ml-auto">
                  <img src="../../../assets/images/newsletter-subscription 1.svg">
               </div>
            </div>
         </div>
       </div>
       <div class="see_action_section">
 <div class=" beat_your_competetion container my-md-5 "  >

      
        <h4>See Automatic in action.</h4>
        <p>Win every lead, and drive repeat business. Industries Win every lead, and drive repeat business. Industries Win every lead, and drive repeat business.</p>
        <img src="../../../assets/images/video-img.svg" class="video-icon">
        </div>
       </div>
      
        </div> -->
</div>


  <div class=" pt-md-5 started">
      <div class="people_section_problem container">

        <h4>what you need to earn money?</h4>
         <div class="row justify-content-center">
            <div class="col-md-3" *ngFor="let details of earn">
                <div class="card mb-md-4">
                    <div class="card-body">
                     <div class="text-center">
                         <h3>{{details.name}}</h3>
                         <p>{{details.details}}</p>
                         <!-- <a>Learn more  > </a> -->
                     </div>
                        
                    </div>
                </div>
            </div>
         </div>
      </div>
   </div>
     <div class=" pt-md-5">
      <div class="beat_your_competetion local_bus   started_button  container">

        <h4>Love visiting local bussiness? Get started</h4>
         <button class="cursor get">Launching soon</button>
      </div>
   </div>

<app-footer></app-footer>