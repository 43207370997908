import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.css'],
})
export class ReferralsComponent implements OnInit {
  cardsDetails: any[] = [
    {
      name: '10x',
      details: 'More Google reviews',
      Image: '../../../assets/images/google-im.svg',
    },
    {
      name: '50%',
      details: 'More leads and enquiries',

      Image: '../../../assets/images/revieews-In.svg',
    },
    {
      name: '14.3%',
      details: 'High Reputation for local business',
      Image: '../../../assets/images/traffic.svg',
    },
  ];
  steepper: any[] = [
    {
      count: 1,
      header:
        'Download Localfirst app and Set up Google Reviews system in minutes',
      details: '',
      view: true,
    },
    {
      count: 2,
      header: 'Enter customer phone number, send review request',
      details: '',
      view: true,
    },
    {
      count: 3,
      header: 'Get Google review in minutes and respond from the app directly',
      details: '',
      view: true,
    },
    {
      count: 4,
      header: 'View and manage all reviews from the app itself',
      details: '',
      view: false,
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
