
<app-navbar></app-navbar>

<div class="main">
     <div class="con">
        <h4>About Us</h4>

        <div>
            <p>
                
              Welcome to LocalFirst!</p>
            <p>
                
              At LocalFirst, we have built a simple software that helps local businesses in India request Google reviews from customers in the easiest way.</p>
            <p>
                
            Our software can be used via a mobile app on both iOS and Android devices and any local business can start using it for free for the first 14 days</p>
            <p>
                
"The software we have built is a blessing for local businesses in India"
              </p>
              <p>

   PK<br>
creator/founder<br>
LocalFirst






            </p>
</div>

<!-- <app-footer></app-footer> -->