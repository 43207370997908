<app-navbar></app-navbar>

<div class="main">
    <div class="main_block_price mb-md-4">
        <h5>Every Plan comes with a    30 day free trial</h5>
<div class="container-fluid">
        <div class="row">
            <div class="col-md-4" *ngFor="let view of pricingDetails">
                <div class="card">
                    <div class="card-body">
                       <h2>{{view.header}}</h2>
                       <p>{{view.para}}</p>
                       <h1>{{view.price}}</h1>
                       <span>{{view.agree}}</span>
                       <h4>{{view.con}} <span>Customer contacts</span></h4>
                       <h4>{{view.mem}} <span> Google Reviews Per month</span></h4>
                       <ul>
                         <h3>{{view.listHeade}}</h3>
                         <li>Track and Reply  to Google reviews </li>
                         <li>Custom Referrals</li>
                         <li>SMS Marketing Tools</li>
                         <li>Autopilot Analytics</li>
                         <li>Team management</li>
                       </ul>
                       <button class="cursor">Launching Soon</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>


<app-footer></app-footer>