import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  cardsDetails: any[] = [
    {
      name: 'Restaurant',
      Image: '../../../assets/images/restaurant.svg',
    },
    {
      name: 'Dental Clinic',
      Image: '../../../assets/images/dental.svg',
    },
    {
      name: 'Beauty Parlour',
      Image: '../../../assets/images/beauty.svg',
    },
    {
      name: 'Skin Care Clinic',
      Image: '../../../assets/images/skincare.svg',
    },
    {
      name: 'Jewellery Shop',
      Image: '../../../assets/images/Jewellers.svg',
    },
  ];

  carddetails2: any = [
    {
      name: 'Hair Saloon',
      Image: '../../../assets/images/saloons.svg',
    },
    {
      name: 'Car Repair Shop',
      Image: '../../../assets/images/car.svg',
    },
    {
      name: 'Footwear Shop',
      Image: '../../../assets/images/footwear.svg',
    },
    {
      name: 'Gym',
      Image: '../../../assets/images/gyms.svg',
    },
    {
      name: 'Pet Shop',
      Image: '../../../assets/images/pets.svg',
    },
  ];
  carddetails3: any = [
    {
      name: 'Furniture Showroom',
      Image: '../../../assets/images/furinture.svg',
    },
    {
      name: 'CA Firm',
      Image: '../../../assets/images/ca.svg',
    },
    {
      name: 'Legal Firm',
      Image: '../../../assets/images/legal.svg',
    },
    {
      name: 'Driving School',
      Image: '../../../assets/images/pets.svg',
    },
    {
      name: 'Garment Shop',
      Image: '../../../assets/images/germent.svg',
    },
  ];
  constructor(private router: Router) {}

  ngOnInit(): void {}
  navigate(item: any) {
    this.router.navigate([`${item.split(' ').join('-')}`]);
  }
}
