import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ServicesService } from './services.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private inject: Injector, private service: ServicesService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authService = this.inject.get<ServicesService>(ServicesService);
    let token = request;
    // let Ctoken = request;

    token = this.addTokenHeader(request, authService.getToken());
    // Ctoken = this.addTokenHeader(request, authService.getCToken());
    // console.log(token);
    return next.handle(token).pipe(
      catchError((errorData: any) => {
        if (errorData.status === 401) {
          authService.logOut();
        }
        return throwError(errorData);
      })
    );
  }

  addTokenHeader(request: HttpRequest<any>, token: any) {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token),
    });
  }
}
