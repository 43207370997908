    
    <app-navbar></app-navbar>
    <div class="container" *ngIf="login1">   
        <main>
            <h1>Dear Local Business Owner</h1>
            <p>To manage your account and access premium features and subscription of our software LocalFirst, please login to your account</p>
            <button class="btn manage-account mb-5 mt-4" (click)="manageAccount()">Manage Account</button>
            <h6>If you have not already downloaded the app</h6>
            <button class="btn download-app">Download the app</button>
            <h6 class="mt-5">Need Help? <a href="#">Contact us</a></h6>
        </main>
    </div> 
<app-loader *ngIf="login1Loader"></app-loader>
     <div class="container login-ph" *ngIf="loginScreen">
        <h1>Enter your phone number</h1>
        <p>Please enter your phone number you used when you sign up on LocalFirst mobile app</p>
     <div class="form-section d-flex">
    
    <div class="d-flex">
        <img src="../../../assets/images/ph.svg">
    </div>
    <div class="name-section mb-3">
       
        <input type="text" class="form-control" pattern="\d*" [(ngModel)]="number" inputmode="numeric" (input)="validate($event)" (keypress)="isNumberKey($event)" [maxLength]="10" placeholder="Enter your phone number">

    </div>
</div>
<p class="err">{{this.err}}</p>
        <button class="btn-next" (click)="next()">Next</button>

        <h5>If you do not have our software app installed already on your phone?<br>
        Please install first <a href="https://play.google.com/store/apps/details?id=com.localfirst.merchantapp" target="_blank">Download</a></h5>
    </div> 

     <div class="container mx-3 " *ngIf="verify" >
<div class="v1">

         <div class="top-nav pt-3" >
        <div>
           
            <h6>Verification</h6>
        </div>
    </div>
     <label id="phone">We will send an OTP to the phone number</label>
<div  class="d-flex verify" >
    <span class="mr-3">+91-{{this.number}}</span>
    <img src="../../../assets/images/edit.svg" class="mr-2 pointer cursor" (click)="changeNumber()">
    <p class="cursor" (click)="changeNumber()">Change</p>
</div>
<ngx-otp-input [class.otp]="otpVerifed==true" [config]="otpInputConfig" (otpChange)="handeOtpChange($event)" (fill)="handleFillEvent($event)">
</ngx-otp-input>
<p class="err">{{this.error}}</p>

<!-- <div class="d-flex timer-text justify-content-between" >
    <div class="d-flex">
        <p>Didn't receive code?</p>
        <button [disabled]="resend" (click)="resendBtn()">Resend</button>

    </div>
    <div class="d-flex" *ngIf="timer">
        <img src="../../../assets/images/timer.svg" alt="">
        <h6>{{ minutes }}:{{ seconds | number: '2.0' }}
            
        </h6>

    </div>
</div> -->
<div class="accept-button">
    <button class="pointer " (click)="nextOtp()">Next</button>
</div>
    </div>
    
</div>
    
    <div class="container mt-4 mb-3" *ngIf="select">
        <h1 class="mb-3">Select your Local Business</h1>
        <div class="business-card selected">
            <div class="business-info">
                <div class="business-details">
                    <h2>{{locationName}}</h2>
                    <!-- <p>{{formatted_address}}</p> -->
                </div>
            </div>
            <div class="check-mark">✔️</div>
        </div>
      
        <button class="btn-manage" (click)="manage()">View account</button>
    </div>



     <div class="container error-m" *ngIf="error1">
        <p class="error-message">We did not find any local business account associated with this phone number</p>
        <h5>You first need to sign up your local business on our app. Download the app and create account</h5>
        <p class="download-text">Download LocalFirst app</p>
        <div class="app-buttons">
            <a href="#"><img src="google-play-badge.png" alt="Get it on Google Play"></a>
            <a href="#"><img src="app-store-badge.png" alt="Download on the App Store"></a>
        </div>
        <div class="help-card">
            <p>Need help?</p>
            <a href="#">Contact us</a>
        </div>
    </div>