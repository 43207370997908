 
 <app-navbar></app-navbar>
 <div class="container">
        <h1 class="mt-4">        Refund Policy
</h1>
        <div class="content mt-4">
            
            <p>Refund Processing: If you are eligible for a refund, it will be processed within 7-10 business days from the date of cancellation confirmation. The refund will be issued to the original payment method used for the subscription.

</p>

            <ul>
<h4>Important Notes</h4>

                <li>Non-Refundable Services: Any additional services or custom features purchased outside of the standard subscription plan are non-refundable.</li>
                <li>Account Access: Upon cancellation, your account will remain active until the end of the current billing cycle. No further charges will be applied, and you will retain access to your account features until the end of the subscription period.
</li>
                
                <li>
Dispute Resolution: If you have any concerns or disputes regarding the cancellation or refund process, please contact our support team at support@localfirst.com. We are committed to resolving any issues promptly and fairly.
</li>
                
            </ul>

         
        </div>



  </div>
  <!-- <app-footer></app-footer> -->