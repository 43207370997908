<!-- 

<div class="brings_main_section">
          <div class="brings_section ">
             <div class="d-md-flex ">
                <div class="brings_view">
                   <h5>Localfirst app helps your local business to grow  everyday</h5>
<div class="d-flex">


</div>
<h6>Launching soon...</h6>
<div class="d-flex play">
   <img src="../../../assets/images/Google Play Badge.svg" class="mr-3">
   <img src="../../../assets/images/Group.svg">
</div>
                </div>
                <div class="ml-auto moblie_mockup">
                   <img src="../../../assets/images/Phone Mockup 1.svg" >
                   <img src="../../../assets/images/Phone Mockup 2.svg">
                </div>
             </div>
          </div>
       </div>

         
<footer>
    <div class="footer_Section mt-md-5">
        <div class="footer_main">
            <div class="row pb-md-5">
                <div class="col">
 <a class="navbar-brand" href="#">
    <div class='d-flex'>
       <img src="../../../assets/images/nav-img.svg" class="mr-2">
        <h4 class="m-0">LocalFirst</h4>
    </div></a>
                </div>
               
                <div class="col">
                     <div class="titl_section mt-5">
    
                        <ul>
                            <li *ngFor="let view of cardsDetails"><a (click)="navigate(view.name)">{{view.name}}</a></li>
                             
                         
                        </ul>
                     </div>
                </div>
                <div class="col">
                     <div class="titl_section mt-5">
      
                        <ul>
                            <li *ngFor="let view of carddetails2"><a (click)="navigate(view.name)">{{view.name}}</a></li>
                             
                         
                        </ul>
                     </div>
                </div>
                <div class="col">
                     <div class="titl_section mt-5">
                      
                        <ul>
                            <li *ngFor="let view of carddetails3"><a (click)="navigate(view.name)">{{view.name}}</a></li>
                            
                     
                          
                         
                        </ul>
                     </div>
                </div>
                   
                
                
            </div>
            


            <div class="footer_bottom_section ">
                <div class="d-md-flex border_padding">
                     
                    <div class="social_accounts_section ml-auto mt-5">
                        <div class="d-flex justify-content-md-end">
                            <img src="../../../assets/images/facebook.svg"  class="">
                            <img src="../../../assets/images/insta.svg" class="ml-3">
                            <img src="../../../assets/images/twiiter.svg" class="ml-3">
                            <img src="../../../assets/images/linkedIN.svg" class="ml-3">
                        </div>
                        <div class="services_footer">
                            <ul class="d-flex">
                                <li><a class="cursor" href="/Term-of-service">Terms of Service </a> </li>
                                <li><a class="cursor" href="/Privacy-policy">Privacy Policy </a> </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer> -->


<footer>
      <div class="services_footer d-md-flex justify-md-content-around">
                            <ul class="d-flex justify-content-center">
                                <li><a class="cursor" href="/AboutUs">About Us </a> </li>
                                <li><a class="cursor" href="/ContactUs">Contact Us</a> </li>
                            </ul>
                            <ul class="d-flex justify-content-center" >
                                <li><a class="cursor" >LocalFirst software solutions private limited </a> </li>
                            </ul>
                            <ul class="d-flex justify-content-center">
                                <li><a class="cursor" href="/Privacy-policy">Privacy Policy </a> </li>

                                <li><a class="cursor" href="/Terms-of-service">Terms of Service </a> </li>
                                <li><a class="cursor" href="/Cancellation-policy">Cancellation policy </a> </li>
                                <li><a class="cursor"  href="/Refund-policy"> Refund policy </a> </li>
                            </ul>
                        </div>
</footer>