 <app-navbar></app-navbar>
 
 <div class="container">
        <h1 class="mt-4">         Cancellation policy</h1>
        <div class="content mt-4">
            
            <p>At LocalFirst, we value our customers and strive to provide exceptional service. However, we understand that circumstances may change, and you may need to cancel your subscription. Below is our cancellation policy to guide you through the process.
Cancellation Window</p>

            <ul>
                <li>Within 5 Days of Payment: Merchants can cancel their subscription within 5 days of the payment date. If you choose to cancel within this window, you are eligible for a full refund.</li>
                <li>After 5 Days: If you cancel your subscription after the 5-day window has passed, no refund will be issued.
</li>
                
            </ul>

         
        </div>



  </div>
  <!-- <app-footer></app-footer> -->